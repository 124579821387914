<template>
  <div class="d-flex justify-content-center" v-if="loaded">
    <b-row :class="`mt-3 ${has_slug ? 'col-12 col-sm-10' :'col-12 col-lg-8 col-xl-5'}`">
      <b-col class="col-12 text-center">
        <h1 class="mb-1">{{ $t('creator.describeCasting') }}</h1>
        <p>{{ $t('creator.influencersexcited') }}</p>
        <b-form-textarea :state="state_description" v-model="casting_call.description" rows="1" max-rows="15" class="mt-2 custom-font" :placeholder="$t('creator.descriptionCasting')" aria-describedby="input-live-help invalid-feedback-description"></b-form-textarea>
        <b-form-invalid-feedback id="invalid-feedback-description">
          <span v-if="casting_call.description && casting_call.description.length <= 10">{{ $t('creator.10characteres') }}</span>
          <span v-else-if="casting_call.description && casting_call.description.length > 2000">{{ $t('creator.2000characters') }}</span>
        </b-form-invalid-feedback>
        <b-col class="col-12 d-flex justify-content-center">
          <b-button variant="blue-button-next-step" class="blue-button-next-step my-2" :disabled="!state_description" @click="save()">{{has_slug ? $t('creator.savePreview') : $t('creator.butonNext')}}</b-button>
        </b-col>
      </b-col>
      <small class="mt-2 w-100 text-center text-muted">{{ $t('creator.requiredDescription') }}</small>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BFormInvalidFeedback,
} from 'bootstrap-vue';
import utils from '@/libs/utils';
import casting_calls_services from '@/services/casting_calls';

export default {
  name: 'stepDescription',
  components: {
    BRow,
    BCol,
    BButton,
    BFormTextarea,
    BFormInvalidFeedback,
  },
  data() {
    return {
      text: '',
      profile: {},
      utils,
      steps: null,
      casting_call: {
        description: ''
      },
      casting_call_uuid: '',
      loaded: false,
    }
  },
  created() {
    this.getData();
  },
  computed: {
    has_slug() {
      return this.$route.params.slug
    },
    state_description() {
      if (this.casting_call.description) return this.casting_call.description.length > 10 && this.casting_call.description.length <= 2000
      else return false
    },
  },
  methods: {
    getData() {
      if (this.has_slug) {
        this.casting_call = {...this.$route.params.casting_call_data};
        this.loaded = true;
        this.casting_call_uuid = this.casting_call.uuid;
      } else {
        this.steps = JSON.parse(localStorage.getItem('steps'));
        this.casting_call_uuid = this.steps.uuid ? this.steps.uuid : this.$route.params.casting_call_uuid
        casting_calls_services.getCastingCall(this.casting_call_uuid).then((response) => {
          this.casting_call = response
          this.loaded = true;
        });
      }
    },
    save() {
      const form_data = {
        'description': this.casting_call.description
      }

      casting_calls_services.updateCastingCall(this.casting_call_uuid, form_data).then((response) => {
        if (response.status === 'OK' && !this.has_slug) this.$router.push({ name: 'steps_casting_call', params: {step: 'visibility'}})
        else this.$emit('update_campaign', response.response);
      })
    },

  }
}
</script>
<style scoped>
.custom-font {
  font-family: Calibri, sans-serif !important;
}
</style>